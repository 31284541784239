.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Nav-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #282c34;
  height: 4rem;
}

.Nav-li {
  float: left;
}

.Nav-li a {
  display: block;
  color: white;
  text-align: center;
  font-size: calc(10px + 2vmin);
  padding: 14px 16px;
  text-decoration: none;
}

.Nav li a:hover {
  background-color: #202329;
}

.App-footer {
  height: 4rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #282c34;
}

.App-footer p {
  color: white;
}

.App-Section ul {
  text-decoration: none;
  list-style-type: none;
  font-size: calc(10px +2vmin);
}

.App-Section ul a {
  text-decoration: none;
  color: white;
}
